import { PublicClientApplication } from "@azure/msal-browser";
import { getOboToken } from "./oboService";


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID /* "815524e3-624b-4cc5-8745-ea533d671b59" */,
        authority:`https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}` /* "https://login.microsoftonline.com/7ecf1dcb-eca3-4727-8201-49cf4c94b669" */,
        redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      }
};


export const msalInstance = new PublicClientApplication(msalConfig);

/* export const initializeMsal = async () => {
    await msalInstance.initialize();
};
 */
export const login = async (mailToken) => {
    const loginRequest = {
        scopes: ["user.read"]
    };
    try {
        //await initializeMsal(); // Ensure MSAL is initialized
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        const response = await getOboToken(loginResponse.accessToken, mailToken);
        console.log('aaaaaa',response)
        return loginResponse.accessToken;

    } catch (error) {
        console.error("Login failed:", error);
        throw error;
    }
};