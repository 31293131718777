/* import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home';
import Login from './login';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = JSON.parse(localStorage.getItem("user"))

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }

    // If the token exists, verify it with the auth server to see if it is valid
    fetch("http://localhost:3080/verify", {
            method: "POST",
            headers: {
                'jwt-token': user.token
              }
        })
        .then(r => r.json())
        .then(r => {
            setLoggedIn('success' === r.message)
            setEmail(user.email || "")
        })
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
 */
/* 
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OtpPage from './components/OtpPage';
import AuthCallback from './components/AuthCallback';
import Success from './components/Success';
import ProtectedRoute from './config/ProtectedRoute';
import Error from './components/Error';



const App = () => (
  <Router>
    <Routes>
      <Route path="/otp/:token" element={<OtpPage />} />
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
      <Route path="/error" element={<Error />} />
    </Routes>
  </Router>
);

export default App;

 */


import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  
} from "react-router-dom";
import ProtectedRoute from "./config/ProtectedRoute";
//import AuthCallback from "./components/AuthCallback";


// Lazy load components
const Otp = lazy(() => import("./components/OtpPage"));
const LoginSsoPage = lazy(() => import("./components/LoginSsoPage"));
const AuthCallback = lazy(() => import("./components/AuthCallback"));
const Success = lazy(() => import("./components/Success"));
const Error = lazy(() => import("./components/Error"));

// Layout component to handle the loading state
const Layout = () => {
  
  

  return (
    <div className="">
        
        <Suspense fallback={<AuthCallback />}>
          <Outlet />
        </Suspense>
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="/otp/:token" element={<Otp />} />
      <Route path="/LoginSsoPage/:token" element={<LoginSsoPage />} />
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
      <Route path="/error" element={<Error />} /> 
    </Route>
  )
  
);


function App() {
  return <RouterProvider router={router} />;
}
export default App