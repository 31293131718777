import axiosInstance from "../config/axiosConfig";

export const getOboToken = async (token, mailToken) => {
    try {
        const response = await axiosInstance.post('/token/getToken', { accessToken: token, encryptedEmail:mailToken});
        return response;
    } catch (error) {
        if (error.response) {          
            throw error.response;
        } else if (error.request) {
            throw new Error('No response from server. Please try again.');
        } else {
            throw new Error('Error. Please try again.');
        }
    }
};
